<template>
  <div class="invest">
    <h2 class="title">
      La
      <span>haute performance de vos investissements</span>
      est notre priorité.
    </h2>
    <div class="content">
      <div class="left">
        <img
          class="samantha"
          loading="lazy"
          width="167"
          height="273"
          src="~/assets/imgs/home/samantha.webp"
          alt=""
        />
        <div class="content-title">Je souhaite être accompagné</div>
        <div class="text">
          Un expert dédié déniche pour vous le bien idéal et vous accompagne tout au long de votre projet.
        </div>
        <NuxtLink
          no-prefetch
          to="https://horiz.io/ressources/investissement-locatif-cle-en-main?utm_source=horiz&utm_medium=hp-cta-3&utm_campaign=cle-en-main"
          @click="tracking('Landing Clé en main')"
        >
          <uikit-button
            type="secondary"
            class="button"
          >
            Être accompagné
          </uikit-button>
        </NuxtLink>
      </div>
      <div class="right">
        <div class="content-title">Je calcule mon rendement locatif</div>
        <div class="text">
          Rendement net, cashflow, charges, fiscalité, impôts, coût de l’emprunt, prix d’achat, loyer…
        </div>
        <NuxtLink
          no-prefetch
          to="/rendement-locatif"
          @click="tracking('Landing Simulateur')"
        >
          <uikit-button
            type="secondary"
            class="button"
          >
            Simuler mon rendement
          </uikit-button>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { UikitButton } from '@hz/ui-kit'

const tracking = (destination) =>
  useTracking().track('[HP] Clic CTA', { destination, positionBouton: 'bandeau secondaire' })
</script>

<style lang="less" scoped>
.invest {
  padding: 60px 0;
  background-color: var(--ds-color-primary-25);

  .title {
    font-size: var(--ds-font-xl);
    font-weight: var(--ds-weight-semi-bold);
    margin: 10px 10px 44px 10px;
    text-align: center;

    span {
      color: var(--ds-color-primary-100);
    }
  }
  .content {
    margin: 0 auto;
    max-width: 1240px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content-title {
      font-size: 24px;
      font-weight: var(--ds-weight-semi-bold);
    }

    .left {
      background-color: var(--ds-color-primary-100);
      border-radius: 12px 12px 0 0;
      position: relative;
      overflow: hidden;

      .content-title {
        color: var(--ds-color-white-100);
      }
      .text {
        color: var(--ds-color-white-100);
      }
      & > div {
        max-width: 60%;
      }
      .samantha {
        position: absolute;
        transform: scaleX(-1);
        bottom: -2px;
        height: 86%;
        width: auto;
        right: -42px;
      }
    }

    .right {
      border-radius: 0 0 12px 12px;
      background-color: var(--ds-color-white-100);

      .text {
        opacity: 65%;
      }

      & > div {
        max-width: 80%;
      }
    }

    .right,
    .left {
      padding: 32px 40px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .text {
        font-size: 18px;
      }
      .button {
        width: fit-content;
      }
    }
  }

  @media @bp-desktop {
    padding: 60px 80px;

    .title {
      font-size: 33px;
    }

    .content {
      flex-direction: row;

      .content-title {
        font-size: var(--ds-font-xl);
      }

      .left {
        border-radius: 12px 0 0 12px;
        padding-left: 160px;

        .samantha {
          height: 220px;
          left: 0;
          transform: scaleX(1);
        }
      }
      .right {
        border-radius: 0 12px 12px 0;
      }
      .right,
      .left {
        min-height: 252px;
        width: fit-content;
        justify-content: space-between;
        .title {
          font-size: var(--ds-font-xl);
        }
        & > div {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
